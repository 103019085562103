<template>
	<v-container fluid class="cont04">
		<fieldset>
			<legend></legend>

			<table class="search-table condition">
				<colgroup>
					<col width="6%">
					<col width="12%">
					<col width="32%">
					<col width="12%">
					<col width="32%">
					<col width="6%">
				</colgroup>
				<tbody>
				<tr>
					<th class="td_dark_white dott lneHgt" rowspan="5">검색<br>조건</th>
					<th class="td_dark_white">
						<label for="process01">업무절차</label>
						<input v-model="param.workFlowCodes" type="checkbox" id="process01" value="ALL" @change="onChangeWorkFlowCode">
					</th>
					<td class="td_dark_blank" colspan="4">
						<p class="line05 my-2">
							<template v-for="code in codes['WKFLW']">
								<span :key="code.codeDetailId + '_1'">
									<input
										v-model="param.workFlowCodes"
										type="checkbox"
										:id="code.codeDetailId + '_wk'"
										:value="code.codeDetailId"
										@change="onChangeWorkFlowCode">
									<label
										:for="code.codeDetailId + '_wk'"
									>{{ code.codeDetailName }}</label>
								</span>
							</template>
						</p>
					</td>
				</tr>
				<tr>
					<th class="td_dark_white">
						<label for="process30">기술유형</label>
						<input v-model="param.techTypes" type="checkbox" id="process30" value="ALL" @change="onChangeTechType">
					</th>
					<td class="td_dark_blank" colspan="4">
						<p class="line05 my-2">
							<template v-for="(code, index) in codes['7030']">
								<span :key="code.codeDetailId + '_1'">
									<input
										v-model="param.techTypes"
										type="checkbox"
										:id="code.codeDetailId + '_te'"
										:value="code.codeDetailId"
										@change="onChangeTechType">
									<label
										:for="code.codeDetailId + '_te'"
									>{{ code.codeDetailName }}</label>
								</span>
								<br v-if="index === 4" :key="code.codeDetailId + '_br'"/>
							</template>
						</p>
					</td>
				</tr>
				<tr>
					<th class="td_dark_white">
						<label for="process31">거래유형</label>
						<input v-model="param.transactionTypes" type="checkbox" id="process31" value="ALL" @change="onChangeTransactionType">
					</th>
					<td class="td_dark_blank" colspan="4">
						<p class="line05 my-2">
							<template v-for="code in codes['7010']">
								<span :key="code.codeDetailId + '_1'">
									<input
										v-model="param.transactionTypes"
										type="checkbox"
										:id="code.codeDetailId + '_tr'"
										:value="code.codeDetailId"
										@change="onChangeTransactionType">
									<label
										:for="code.codeDetailId + '_tr'"
									>{{ code.codeDetailName }}</label>
								</span>
							</template>
						</p>
					</td>
				</tr>
				<tr>
					<th class="td_dark_white">
            <label for="process32">상세검색</label>
          </th>
					<td class="td_dark_blank" colspan="4">
						<p class="line05 my-2">
							<span><input v-model="detailSearchItems" type="checkbox" id="process26" value="A" @change="onChangeDetailSearchItem"><label for="process26">입금내용</label></span>
							<span><input v-model="detailSearchItems" type="checkbox" id="process27" value="B" @change="onChangeDetailSearchItem"><label for="process27">기술 및 지식재산권</label></span>
							<span><input v-model="detailSearchItems" type="checkbox" id="process28" value="C" @change="onChangeDetailSearchItem"><label for="process28">발명자 정보</label></span>
							<span><input v-model="detailSearchItems" type="checkbox" id="process29" value="D" @change="onChangeDetailSearchItem"><label for="process29">관련 연구과제</label></span>
						</p>
					</td>
				</tr>
				<tr>
					<th class="td_dark_white dott"><label>기술이전 계약일</label></th>
					<td class="td_dark_white dott">
						<div class="search-option-wrapper-right">
							<div class="form-checkbox check-all form-checkbox-sm">
								<input v-model="checkAllContractDate" type="checkbox" id="contract-date">
								<label for="contract-date">전체</label>
							</div>
							<div class="recent-date-wrapper">
								<span class="date-text">최근</span>
								<input v-model="latestContractYears" class="form-input form-input-sm" id="latest" maxlength="2" :readonly="checkAllContractDate" @click="onClickContractDate">

								<span class="date-text">년</span>
							</div>
							<div class="recent-period-wrapper">
								<input v-model="param.startContractDate" class="form-input form-input-sm" style="width:120px" type="date" id="dateFrom" max="9999-12-31" :readonly="checkAllContractDate" @click="onClickContractDate">
								<span class="date-text">~</span>
								<input v-model="param.endContractDate" class="form-input form-input-sm" style="width:120px" type="date" id="dateTo" max="9999-12-31" :readonly="checkAllContractDate" @click="onClickContractDate">

							</div>
						</div>
					</td>
					<td class="td_dark_white dott"><label>기술료 입금일</label></td>
					<td class="td_dark_white dott" colspan="2">
						<div class="search-option-wrapper-right">
							<div class="form-checkbox check-all form-checkbox-sm">
								<input v-model="checkAllDepositDate" type="checkbox" id="document-date">
								<label for="document-date">전체</label>
							</div>
							<div class="recent-date-wrapper">
								<span class="date-text">최근</span>
								<input v-model="latestDepositYears" class="form-input form-input-sm" id="latest" maxlength="2" :readonly="checkAllDepositDate" @click="onClickDepositDate">
								<span class="date-text">년</span>
							</div>
							<div class="recent-period-wrapper">
								<input v-model="param.startDepositDate" class="form-input form-input-sm" style="width:120px" type="date" id="dateFrom2" max="9999-12-31" :readonly="checkAllDepositDate" @click="onClickDepositDate">
								<span class="date-text">~</span>
								<input v-model="param.endDepositDate" class="form-input form-input-sm" style="width:120px" type="date" id="dateTo2" max="9999-12-31" :readonly="checkAllDepositDate" @click="onClickDepositDate">
								<span class="recent-date-alert" style="visibility: hidden;"></span>
							</div>
						</div>
					</td>
				</tr>
				<tr>
					<td class="td_dark_white dott lneHgt" rowspan="6">검색<br>입력</td>
					<td class="td_dark_white">
						<label for="techper01">기술이전 책임자</label>
					</td>
					<td class="td_dark_white">
						<input v-model="param.techTransManagerName" type="text" style="width:96%" class="blue_input" id="techper01" @keydown.enter="search">
					</td>
					<td class="td_dark_white">
						<label for="techper02">계약관리 담당자</label>
					</td>
					<td class="td_dark_white">
						<input v-model="param.contractManagerName" type="text" style="width:96%" class="blue_input" id="techper02" @keydown.enter="search">
					</td>
					<td class="td_dark_white mer" rowspan="2">
						<button type="button" @click="search">검색</button>
					</td>
				</tr>
				<tr>
					<td class="td_dark_white">
						<label for="techper03">관리번호</label>
					</td>
					<td class="td_dark_white">
						<input v-model="param.manageNo" type="text" style="width:96%" class="blue_input" id="techper03" @keydown.enter="search">
					</td>
					<td class="td_dark_white">
						<label for="techper04">신청번호</label>
					</td>
					<td class="td_dark_white">
						<input v-model="param.applyNo" type="text" style="width:96%" class="blue_input" id="techper04" @keydown.enter="search">
					</td>
				</tr>
				<tr>
					<td scope="row" colspan="5" class="bor_right dott">
						<a id="viewhidden" class="button" @click="openMore = !openMore">검색필드 더보기</a>

						<table v-show="openMore" width="100%" cellspacing="0" cellpadding="4px"  id="hidden">
							<colgroup>
								<col width="18%">
								<col width="29%">
								<col width="18%">
								<col width="35%">
							</colgroup>
							<tbody>

							<tr>
								<td class="td_border borno01">
									<label for="techper05">업체명</label>
								</td>
								<td class="td_border">
									<input v-model="param.companyName" type="text" style="width:96%" class="blue_input" id="techper05" @keydown.enter="search">
								</td>
								<td class="td_border">
									<label for="techper06">신청인</label>
								</td>
								<td class="td_border borno02" colspan="2">
									<input v-model="param.applicantName" type="text" style="width:96%" class="blue_input" id="techper06" @keydown.enter="search">
								</td>
							</tr>
							<tr>
								<td class="td_border borno01">
									<label for="techper07">사업자등록번호</label>
								</td>
								<td class="td_border">
									<input v-model="param.registerNo" type="text" style="width:96%" class="blue_input" id="techper07" @keydown.enter="search">
								</td>
								<td class="td_border">
									<label for="techper08">발명자</label>
								</td>
								<td class="td_border borno02" colspan="2">
									<input v-model="param.inventorName" type="text" style="width:96%" class="blue_input" id="techper08" @keydown.enter="search">
								</td>
							</tr>
							<tr>
								<td class="td_border borno01 borno03">
									<label for="techper09">출원번호</label>
								</td>
								<td class="td_border borno03">
									<input v-model="param.applicationNo" type="text" style="width:96%" class="blue_input" id="techper09" @keydown.enter="search">
								</td>
								<td class="td_border borno03">
									<label for="techper10">기술명(발명의 명칭)</label>
								</td>
								<td class="td_border borno02 borno03" colspan="2">
									<input v-model="param.techName" type="text" style="width:96%" class="blue_input" id="techper10" @keydown.enter="search">
								</td>
							</tr>
							</tbody>
						</table>
					</td>
				</tr>
				</tbody>
			</table>
		</fieldset>
    <fieldset>
      <legend></legend>

      <table width="100%" cellspacing="0" cellpadding="4px" class="noline">
        <colgroup>
          <col width="6%">
          <col width="94%">
        </colgroup>
        <tbody>
        <tr>
          <th class="td_dark_white lneHgt" style="vertical-align: top; padding-top: 20px;" rowspan="2">검색<br>결과 <button type="button" id="modal-open21" @click="openSearchItemDialog">출력항목<br>설정</button></th>
          <td class="td_dark_white">
            <div class="allWrap">
              <v-row>
                <v-col cols="1">
                  <v-btn :color="selectedFilter() ? '#b8d1f6' : '#d6ecfa'" block height="100%" min-height="80px" elevation="0" @click="filterItems()">전체 ({{orgItems.length}})</v-btn>
                </v-col>
                <v-col cols="11">
                  <v-row class="mb-2" no-gutters>
                    <v-btn v-if="countItem('workFlowCode', 'RB') > 0" :color="selectedFilter('workFlowCode', 'RB') ? '#b8d1f6' : '#d6ecfa'" class="px-2 mx-1" elevation="0" @click="filterItems('workFlowCode', 'RB')">임시저장 ({{ countItem('workFlowCode', 'RB') }})</v-btn>
                    <v-btn v-if="countItem('workFlowCode', 'RI') > 0" :color="selectedFilter('workFlowCode', 'RI') ? '#b8d1f6' : '#d6ecfa'" class="px-2 mx-1" elevation="0" @click="filterItems('workFlowCode', 'RI')">신청 ({{ countItem('workFlowCode', 'RI') }})</v-btn>
                    <v-btn v-if="countItem('workFlowCode', 'AP') > 0" :color="selectedFilter('workFlowCode', 'AP') ? '#b8d1f6' : '#d6ecfa'" class="px-2 mx-1" elevation="0" @click="filterItems('workFlowCode', 'AP')">승인 ({{ countItem('workFlowCode', 'AP') }})</v-btn>
                    <v-btn v-if="countItem('workFlowCode', 'CI') > 0" :color="selectedFilter('workFlowCode', 'CI') ? '#b8d1f6' : '#d6ecfa'" class="px-2 mx-1" elevation="0" @click="filterItems('workFlowCode', 'CI')">계약체결 ({{ countItem('workFlowCode', 'CI') }})</v-btn>
                    <v-btn v-if="countItem('workFlowCode', 'TD') > 0" :color="selectedFilter('workFlowCode', 'TD') ? '#b8d1f6' : '#d6ecfa'" class="px-2 mx-1" elevation="0" @click="filterItems('workFlowCode', 'TD')">기술료입금 ({{ countItem('workFlowCode', 'TD') }})</v-btn>
                    <v-btn v-if="countItem('workFlowCode', 'ED') > 0" :color="selectedFilter('workFlowCode', 'ED') ? '#b8d1f6' : '#d6ecfa'" class="px-2 mx-1" elevation="0" @click="filterItems('workFlowCode', 'ED')">종료 ({{ countItem('workFlowCode', 'ED') }})</v-btn>
                    <v-btn v-if="countItem('workFlowCode', 'RC') > 0" :color="selectedFilter('workFlowCode', 'RC') ? '#b8d1f6' : '#d6ecfa'" class="px-2 mx-1" elevation="0" @click="filterItems('workFlowCode', 'RC')">신청취소 ({{ countItem('workFlowCode', 'RC') }})</v-btn>
                    <v-btn v-if="countItem('workFlowCode', 'CC') > 0" :color="selectedFilter('workFlowCode', 'CC') ? '#b8d1f6' : '#d6ecfa'" class="px-2 mx-1" elevation="0" @click="filterItems('workFlowCode', 'CC')">계약해지 ({{ countItem('workFlowCode', 'CC') }})</v-btn>
                  </v-row>
                  <v-row no-gutters>
                    <v-btn v-if="countItem('repTechType', '0001') > 0" :color="selectedFilter('repTechType', '0001') ? '#b8d1f6' : '#d5ecfa'" class="px-2 mx-1" elevation="0" @click="filterItems('repTechType', '0001')">특허 ({{ countItem('repTechType', '0001') }})</v-btn>
                    <v-btn v-if="countItem('repTechType', '0007') > 0" :color="selectedFilter('repTechType', '0007') ? '#b8d1f6' : '#d5ecfa'" class="px-2 mx-1" elevation="0" @click="filterItems('repTechType', '0007')">노하우 ({{ countItem('repTechType', '0007') }})</v-btn>
                    <v-btn v-if="countItem('repTechType', '0009') > 0" :color="selectedFilter('repTechType', '0009') ? '#b8d1f6' : '#d5ecfa'" class="px-2 mx-1" elevation="0" @click="filterItems('repTechType', '0009')">자문 ({{ countItem('repTechType', '0009') }})</v-btn>
                    <v-btn v-if="countItem('repTechType', '0002') > 0" :color="selectedFilter('repTechType', '0002') ? '#b8d1f6' : '#d5ecfa'" class="px-2 mx-1" elevation="0" @click="filterItems('repTechType', '0002')">실용실안 ({{ countItem('repTechType', '0002') }})</v-btn>
                    <v-btn v-if="countItem('repTechType', '0003') > 0" :color="selectedFilter('repTechType', '0003') ? '#b8d1f6' : '#d5ecfa'" class="px-2 mx-1" elevation="0" @click="filterItems('repTechType', '0003')">디자인, 의장 ({{ countItem('repTechType', '0003') }})</v-btn>
                    <v-btn v-if="countItem('repTechType', '0004') > 0" :color="selectedFilter('repTechType', '0004') ? '#b8d1f6' : '#d5ecfa'" class="px-2 mx-1" elevation="0" @click="filterItems('repTechType', '0004')">상표 ({{ countItem('repTechType', '0004') }})</v-btn>
                    <v-btn v-if="countItem('repTechType', '0005') > 0" :color="selectedFilter('repTechType', '0005') ? '#b8d1f6' : '#d5ecfa'" class="px-2 mx-1" elevation="0" @click="filterItems('repTechType', '0005')">소프트웨어 ({{ countItem('repTechType', '0005') }})</v-btn>
                    <v-btn v-if="countItem('repTechType', '0006') > 0" :color="selectedFilter('repTechType', '0006') ? '#b8d1f6' : '#d5ecfa'" class="px-2 mx-1" elevation="0" @click="filterItems('repTechType', '0006')">저작권 ({{ countItem('repTechType', '0006') }})</v-btn>
                    <v-btn v-if="countItem('repTechType', '0008') > 0" :color="selectedFilter('repTechType', '0008') ? '#b8d1f6' : '#d5ecfa'" class="px-2 mx-1" elevation="0" @click="filterItems('repTechType', '0008')">MTA ({{ countItem('repTechType', '0008') }})</v-btn>
                    <v-btn v-if="countItem('repTechType', '0099') > 0" :color="selectedFilter('repTechType', '0099') ? '#b8d1f6' : '#d5ecfa'" class="px-2 mx-1" elevation="0" @click="filterItems('repTechType', '0099')">기타 ({{ countItem('repTechType', '0099') }})</v-btn>
                  </v-row>
                </v-col>
              </v-row>
            </div><!--.allWrap-->

          </td>

        </tr>

        <tr>

          <th class="td_dark_blank padleftno pb-0">
            <div class="filterWrap clear">
              <div class="fitericon" :style="{'width': openFilter ? '16%' : '5%'}">
                <button type="button" class="filterImage open50" @click="openFilter = true">
                  <img :src="require('@/assets/img/filter.png')" alt="">
                </button>
                <div v-show="openFilter" id="noneDiv">
                  <a href="#" class="close50" @click="openFilter = false">닫기</a>
                  <p class="ma-0 marb clear">
                    <button type="button" class="refresh" @click="resetSideFilter"><v-icon>mdi-refresh</v-icon></button>
                  </p>

                  <v-list expand>
                    <v-list-group
                      v-for="(item, index) in filteredItems"
                      :key="index"
                    >
                      <template v-slot:activator>
                        <v-list-item-title>{{ item.text }}</v-list-item-title>
                      </template>

                      <v-list-item
                        v-for="(ch, cidx) in getSideFilterValue(item)"
                        :key="cidx"
                      >
                        <v-list-item-title>
                          <label>
                            <input v-model="sideFilterModel" type="checkbox" :value="ch.value" @change="onChangeSideFilter(item.value, $event)">
                            {{ `(${ch.count}) ` }}
                            <template v-if="item.format === 'date'">
                              {{ dateFormat(ch.value) }}
                            </template>
                            <template v-else-if="item.format === 'money'">
                              {{ moneyFormat(ch.value) }}
                            </template>
                            <template v-else-if="item.code">
                              {{ getCodeDetailName(item.code, ch.value) }}
                            </template>
                            <template v-else>
                              {{ ch.value }}
                            </template>
                          </label>
                        </v-list-item-title>
<!--                        <template v-slot:default="{}">-->
<!--                          <v-list-item-content>-->
<!--                          </v-list-item-content>-->
<!--                        </template>-->
                      </v-list-item>
                    </v-list-group>
                  </v-list>
                  <ul class="pa-0 menuWrap clear">
                  </ul>
                </div>
              </div>
              <div class="filtercont" :style="{'width': openFilter ? '83%' : '94%'}">
                <fieldset>
                  <legend></legend>

                  <table width="100%" cellspacing="0" cellpadding="4px" style="margin-bottom: 10px">
                    <colgroup>
                      <col width="3%">
                      <col width="3%">
                      <col/>
                    </colgroup>
                    <thead>
                      <tr>
                        <th class="td_dark_blue"><input type="checkbox" id="filcon01" name="filconAll" :checked="checkAll" @change="onChangeCheckAll"></th>
                        <th class="td_dark_blue">NO.</th>
                        <th v-if="containSearchItem('manageNo')" class="td_dark_blue" @click="setSort('manageNo')">관리번호 <v-icon v-if="checkSort('manageNo')" small>{{ checkSort('manageNo') }}</v-icon></th>
                        <th v-if="containSearchItem('techTransDate')" class="td_dark_blue" @click="setSort('techTransDate')">계약일 <v-icon v-if="checkSort('techTransDate')" small>{{ checkSort('techTransDate') }}</v-icon></th>
                        <th v-if="containSearchItem('repTechType')" class="td_dark_blue" @click="setSort('repTechType')">기술유형(대표) <v-icon v-if="checkSort('repTechType')" small>{{ checkSort('repTechType') }}</v-icon></th>
                        <th v-if="containSearchItem('techTransManagerName')" class="td_dark_blue" @click="setSort('techTransManagerName')">책임자 <v-icon v-if="checkSort('techTransManagerName')" small>{{ checkSort('techTransManagerName') }}</v-icon></th>
                        <th v-if="containSearchItem('techTransManagerDepartment')" class="td_dark_blue" @click="setSort('techTransManagerDepartment')">책임자 소속 <v-icon v-if="checkSort('techTransManagerDepartment')" small>{{ checkSort('techTransManagerDepartment') }}</v-icon></th>
                        <th v-if="containSearchItem('companyName')" class="td_dark_blue" @click="setSort('companyName')">업체명 <v-icon v-if="checkSort('companyName')" small>{{ checkSort('companyName') }}</v-icon></th>
                        <th v-if="containSearchItem('repTechName')" class="td_dark_blue" @click="setSort('repTechName')" style="max-width: 200px">기술명(대표) <v-icon v-if="checkSort('repTechName')" small>{{ checkSort('repTechName') }}</v-icon></th>
                        <th v-if="containSearchItem('totalAmountSum')" class="td_dark_blue" @click="setSort('totalAmountSum')">입금총액 <v-icon v-if="checkSort('totalAmountSum')" small>{{ checkSort('totalAmountSum') }}</v-icon></th>
                        <th v-if="containSearchItem('contractTotalAmount')" class="td_dark_blue" @click="setSort('contractTotalAmount')">계약총액 <v-icon v-if="checkSort('contractTotalAmount')" small>{{ checkSort('contractTotalAmount') }}</v-icon></th>
                        <th v-if="containSearchItem('workFlowCode')" class="td_dark_blue" @click="setSort('workFlowCode')">업무절차 <v-icon v-if="checkSort('workFlowCode')" small>{{ checkSort('workFlowCode') }}</v-icon></th>
                        <th v-if="containSearchItem('transactionType')" class="td_dark_blue" @click="setSort('transactionType')">거래유형 <v-icon v-if="checkSort('transactionType')" small>{{ checkSort('transactionType') }}</v-icon></th>
                        <th v-if="containSearchItem('contractTechFeeReceiptType')" class="td_dark_blue" @click="setSort('contractTechFeeReceiptType')">기술료 수취유형(계약) <v-icon v-if="checkSort('contractTechFeeReceiptType')" small>{{ checkSort('contractTechFeeReceiptType') }}</v-icon></th>
                        <th v-if="containSearchItem('contractPeriod')" class="td_dark_blue" @click="setSort('contractPeriod')">계약종료일 <v-icon v-if="checkSort('contractPeriod')" small>{{ checkSort('contractPeriod') }}</v-icon></th>
                        <th v-if="containSearchItem('depositYn')" class="td_dark_blue" @click="setSort('depositYn')">입금여부 <v-icon v-if="checkSort('depositYn')" small>{{ checkSort('depositYn') }}</v-icon></th>
                        <th v-if="containSearchItem('step')" class="td_dark_blue" @click="setSort('step')">차수 <v-icon v-if="checkSort('step')" small>{{ checkSort('step') }}</v-icon></th>
                        <th v-if="containSearchItem('techFeeReceiptType')" class="td_dark_blue" @click="setSort('techFeeReceiptType')">기술료 수취유형(입금) <v-icon v-if="checkSort('techFeeReceiptType')" small>{{ checkSort('techFeeReceiptType') }}</v-icon></th>
                        <th v-if="containSearchItem('depositDate')" class="td_dark_blue" @click="setSort('depositDate')">입금일 <v-icon v-if="checkSort('depositDate')" small>{{ checkSort('depositDate') }}</v-icon></th>
                        <th v-if="containSearchItem('payInventorRewardYn')" class="td_dark_blue" @click="setSort('payInventorRewardYn')">발명자 보상금 지급여부 <v-icon v-if="checkSort('payInventorRewardYn')" small>{{ checkSort('payInventorRewardYn') }}</v-icon></th>
                        <th v-if="containSearchItem('totalAmount')" class="td_dark_blue" @click="setSort('totalAmount')">입금금액(입금예정금액) <v-icon v-if="checkSort('totalAmount')" small>{{ checkSort('totalAmount') }}</v-icon></th>
                        <th v-if="containSearchItem('representTechYn')" class="td_dark_blue" @click="setSort('representTechYn')">대표기술 여부(Y/N) <v-icon v-if="checkSort('representTechYn')" small>{{ checkSort('representTechYn') }}</v-icon></th>
                        <th v-if="containSearchItem('techType')" class="td_dark_blue" @click="setSort('techType')">기술유형 <v-icon v-if="checkSort('techType')" small>{{ checkSort('techType') }}</v-icon></th>
                        <th v-if="containSearchItem('techName')" class="td_dark_blue" @click="setSort('techName')">기술명(발명의 명칭) <v-icon v-if="checkSort('techName')" small>{{ checkSort('techName') }}</v-icon></th>
                        <th v-if="containSearchItem('applicationNo')" class="td_dark_blue" @click="setSort('applicationNo')">출원번호 <v-icon v-if="checkSort('applicationNo')" small>{{ checkSort('applicationNo') }}</v-icon></th>
                        <th v-if="containSearchItem('registrationNo')" class="td_dark_blue" @click="setSort('registrationNo')">등록번호 <v-icon v-if="checkSort('registrationNo')" small>{{ checkSort('registrationNo') }}</v-icon></th>
                        <th v-if="containSearchItem('inventorName')" class="td_dark_blue" @click="setSort('inventorName')">성명 <v-icon v-if="checkSort('inventorName')" small>{{ checkSort('inventorName') }}</v-icon></th>
                        <th v-if="containSearchItem('inventorDepartment')" class="td_dark_blue" @click="setSort('inventorDepartment')">소속 <v-icon v-if="checkSort('inventorDepartment')" small>{{ checkSort('inventorDepartment') }}</v-icon></th>
                        <th v-if="containSearchItem('inventorCode')" class="td_dark_blue" @click="setSort('inventorCode')">주민번호 <v-icon v-if="checkSort('inventorCode')" small>{{ checkSort('inventorCode') }}</v-icon></th>
                        <th v-if="containSearchItem('inventorType')" class="td_dark_blue" @click="setSort('inventorType')">신분구분 <v-icon v-if="checkSort('inventorType')" small>{{ checkSort('inventorType') }}</v-icon></th>
                        <th v-if="containSearchItem('contributeRation')" class="td_dark_blue" @click="setSort('contributeRation')">지분(%) <v-icon v-if="checkSort('contributeRation')" small>{{ checkSort('contributeRation') }}</v-icon></th>
                        <th v-if="containSearchItem('inventorReward')" class="td_dark_blue" @click="setSort('inventorReward')">발명자보상금(개인) <v-icon v-if="checkSort('inventorReward')" small>{{ checkSort('inventorReward') }}</v-icon></th>
                        <th v-if="containSearchItem('representProjectYn')" class="td_dark_blue" @click="setSort('representProjectYn')">대표과제 여부(Y/N) <v-icon v-if="checkSort('representProjectYn')" small>{{ checkSort('representProjectYn') }}</v-icon></th>
                        <th v-if="containSearchItem('projectNo')" class="td_dark_blue" @click="setSort('projectNo')">과제번호 <v-icon v-if="checkSort('projectNo')" small>{{ checkSort('projectNo') }}</v-icon></th>
                        <th v-if="containSearchItem('projectManager')" class="td_dark_blue" @click="setSort('projectManager')">과제책임자 <v-icon v-if="checkSort('projectManager')" small>{{ checkSort('projectManager') }}</v-icon></th>
                        <th v-if="containSearchItem('totalResearchExpenses')" class="td_dark_blue" @click="setSort('totalResearchExpenses')">관리연구소 <v-icon v-if="checkSort('totalResearchExpenses')" small>{{ checkSort('totalResearchExpenses') }}</v-icon></th>
                        <th v-if="containSearchItem('managerLab')" class="td_dark_blue" @click="setSort('managerLab')">지분(%) <v-icon v-if="checkSort('managerLab')" small>{{ checkSort('managerLab') }}</v-icon></th>
                        <th v-if="containSearchItem('researchContributeRation')" class="td_dark_blue" @click="setSort('researchContributeRation')">총연구비 <v-icon v-if="checkSort('researchContributeRation')" small>{{ checkSort('researchContributeRation') }}</v-icon></th>
                      </tr>
                    </thead>
                    <thead v-if="loading">
                    <tr>
                      <th :colspan="param.searchItems.length + 2" class="td_loading">
                        <v-progress-linear
                          indeterminate
                          color="#0f2585"
                        ></v-progress-linear>
                      </th>
                    </tr>
                    </thead>
                    <tbody v-if="items.length > 0">
                    <tr v-for="(item, index) in items" :key="index" :class="{'even': index % 2 > 0}">
                      <td v-if="item.master || index === 0 || (index > 0 && item.contractTechId !== items[index - 1].contractTechId)" :rowspan="item.rows" class="td_dark_white"><input v-model="selectedItems" type="checkbox" id="filcon02" name="filconAll" :value="item.contractTechId"></td>
                      <td v-if="item.master || index === 0 || (index > 0 && item.contractTechId !== items[index - 1].contractTechId)" :rowspan="item.rows" class="td_dark_white">{{ item.index + 1 }}</td>
                      <td v-if="containSearchItem('manageNo')" @dblclick="itemSelect(item)" class="td_dark_white">{{ item.manageNo }}</td>
                      <td v-if="containSearchItem('techTransDate')" @dblclick="itemSelect(item)" class="td_dark_white">{{ dateFormat(item.techTransDate) }}</td>
                      <td v-if="containSearchItem('repTechType')" @dblclick="itemSelect(item)" class="td_dark_white">{{ getCodeDetailName('7030', item.repTechType) }}</td>
                      <td v-if="containSearchItem('techTransManagerName')" @dblclick="itemSelect(item)" class="td_dark_white">{{ item.techTransManagerName }}</td>
                      <td v-if="containSearchItem('techTransManagerDepartment')" @dblclick="itemSelect(item)" class="td_dark_white">{{ item.techTransManagerDepartment }}</td>
                      <td v-if="containSearchItem('companyName')" @dblclick="itemSelect(item)" class="td_dark_white">{{ item.companyName }}</td>
                      <td v-if="containSearchItem('repTechName')" @dblclick="itemSelect(item)" class="td_dark_white" style="max-width: 150px; word-break: keep-all;">{{ item.repTechName }}</td>
                      <td v-if="containSearchItem('totalAmountSum')" @dblclick="itemSelect(item)" class="td_dark_white">{{ moneyFormat(item.totalAmountSum) }}</td>
                      <td v-if="containSearchItem('contractTotalAmount')" @dblclick="itemSelect(item)" class="td_dark_white">{{ moneyFormat(item.contractTotalAmount) }}</td>
                      <td v-if="containSearchItem('workFlowCode')" @dblclick="itemSelect(item)" class="td_dark_white">{{ getCodeDetailName('WKFLW', item.workFlowCode) }}</td>
                      <td v-if="containSearchItem('transactionType')" @dblclick="itemSelect(item)" class="td_dark_white">{{ getCodeDetailName('7010', item.transactionType) }}</td>
                      <td v-if="containSearchItem('contractTechFeeReceiptType')" @dblclick="itemSelect(item)" class="td_dark_white">{{ getCodeDetailName('7040', item.contractTechFeeReceiptType) }}</td>
                      <td v-if="containSearchItem('contractPeriod')" @dblclick="itemSelect(item)" class="td_dark_white">{{ item.contractPeriod }}</td>
                      <td v-if="containSearchItem('depositYn')" @dblclick="itemSelect(item)" class="td_dark_white">{{ item.depositYn }}</td>
                      <td v-if="containSearchItem('step')" @dblclick="itemSelect(item)" class="td_dark_white">{{ item.step }}</td>
                      <td v-if="containSearchItem('techFeeReceiptType')" @dblclick="itemSelect(item)" class="td_dark_white">{{ getCodeDetailName('7040', item.techFeeReceiptType) }}</td>
                      <td v-if="containSearchItem('depositDate')" @dblclick="itemSelect(item)" class="td_dark_white">{{ dateFormat(item.depositDate) }}</td>
                      <td v-if="containSearchItem('payInventorRewardYn')" @dblclick="itemSelect(item)" class="td_dark_white">{{ item.payInventorRewardYn }}</td>
                      <td v-if="containSearchItem('totalAmount')" @dblclick="itemSelect(item)" class="td_dark_white">{{ moneyFormat(item.totalAmount) }}</td>
                      <td v-if="containSearchItem('representTechYn')" @dblclick="itemSelect(item)" class="td_dark_white">{{ item.representTechYn }}</td>
                      <td v-if="containSearchItem('techType')" @dblclick="itemSelect(item)" class="td_dark_white">{{ getCodeDetailName('7030', item.techType) }}</td>
                      <td v-if="containSearchItem('techName')" @dblclick="itemSelect(item)" class="td_dark_white">{{ item.techName }}</td>
                      <td v-if="containSearchItem('applicationNo')" @dblclick="itemSelect(item)" class="td_dark_white">{{ item.applicationNo }}</td>
                      <td v-if="containSearchItem('registrationNo')" @dblclick="itemSelect(item)" class="td_dark_white">{{ item.registrationNo }}</td>
                      <td v-if="containSearchItem('inventorName')" @dblclick="itemSelect(item)" class="td_dark_white">{{ item.inventorName }}</td>
                      <td v-if="containSearchItem('inventorDepartment')" @dblclick="itemSelect(item)" class="td_dark_white">{{ item.inventorDepartment }}</td>
                      <td v-if="containSearchItem('inventorCode')" @dblclick="itemSelect(item)" class="td_dark_white">{{ getRegisterNoFormat(item.inventorCode) }}</td>
                      <td v-if="containSearchItem('inventorType')" @dblclick="itemSelect(item)" class="td_dark_white">{{ getCodeDetailName('USRTP', item.inventorType) }}</td>
                      <td v-if="containSearchItem('contributeRation')" @dblclick="itemSelect(item)" class="td_dark_white">{{ item.contributeRation }}</td>
                      <td v-if="containSearchItem('inventorReward')" @dblclick="itemSelect(item)" class="td_dark_white">{{ moneyFormat(item.inventorReward) }}</td>
                      <td v-if="containSearchItem('representProjectYn')" @dblclick="itemSelect(item)" class="td_dark_white">{{ item.representProjectYn }}</td>
                      <td v-if="containSearchItem('projectNo')" @dblclick="itemSelect(item)" class="td_dark_white">{{ item.projectNo }}</td>
                      <td v-if="containSearchItem('projectManager')" @dblclick="itemSelect(item)" class="td_dark_white">{{ item.projectManager }}</td>
                      <td v-if="containSearchItem('totalResearchExpenses')" @dblclick="itemSelect(item)" class="td_dark_white">{{ moneyFormat(item.totalResearchExpenses) }}</td>
                      <td v-if="containSearchItem('managerLab')" @dblclick="itemSelect(item)" class="td_dark_white">{{ item.managerLab }}</td>
                      <td v-if="containSearchItem('researchContributeRation')" @dblclick="itemSelect(item)" class="td_dark_white">{{ item.researchContributeRation }}</td>
                    </tr>
                    </tbody>
                    <tbody v-else>
                    <tr>
                      <td class="no-data" :colspan="param.searchItems.length + 2">데이터를 찾을 수 없습니다.</td>
                    </tr>
                    </tbody>
                  </table>
                </fieldset>
              </div>
            </div><!--.filterWrap-->
          </th>
        </tr>
        </tbody>
      </table>
    </fieldset>

    <div class="fixchoice" :style="{'bottom': fixchoiceBottom, 'width': fixChoiceWidth}">
      <span>선택한 계약건 {{ selectedItems.length }}건</span> <input type="checkbox" id="fixch" style="vertical-align: middle" :checked="checkAll" @change="onChangeCheckAll"><label class="ml-1" style="vertical-align: middle" for="fixch">검색건 전부선택</label><button type="button" @click="openDownloadApp">다운로드</button>
    </div>

    <v-dialog v-model="searchItemDialog" max-width="500px">
      <search-item-dialog v-if="searchItemDialog" :search-items="param.searchItems" :detail-search-items="detailSearchItems" @close="closeSearchItemDialog"/>
    </v-dialog>
    <loading-dialog :loading="initLoading"/>
  </v-container>
</template>

<script>
import SearchItemDialog from "@/views/tech/search/dialog/SearchItemDialog";
import moment from "moment";
import LoadingDialog from "@/views/dialog/LoadingDialog";
const initSearchItems = [
  'manageNo', 'techTransDate', 'repTechType', 'techTransManagerName', 'techTransManagerDepartment',
  'companyName', 'repTechName', 'totalAmountSum', 'contractTotalAmount'
]

export default {
	name: 'ContractTechSearch',
  components: {SearchItemDialog, LoadingDialog},
  data() {
		return {
      initLoading: false,
			param: {
				workFlowCodes: [],
				techTypes: [],
				transactionTypes: [],
        searchItems: [],
        startContractDate: null,
        endContractDate: null,
        startDepositDate: null,
        endDepositDate: null,
        techTransManagerName: null,
        contractManagerName: null,
        manageNo: null,
        applyNo: null,
        companyName: null,
        applicantName: null,
        registerNo: null,
        inventorName: null,
        applicationNo: null,
        techName: null,
        detailSearchItem: null,
        sort: ''
			},
      detailSearchItems: [],
      checkAllContractDate: true,
      latestContractYears: null,
      checkAllDepositDate: true,
      latestDepositYears: null,
			openMore: false,
      openFilter: false,
      searchItemDialog: false,
			codes: {},
      loading: false,
      items: [],
      orgItems: [],
      currFilter: null,
      selectedItems: [],
      fixchoiceBottom: '75px',
      sideFilterItems: [],
      sideFilterModel: []
		}
	},
  computed: {
    checkAll() {
      return this.selectedItems.length > 0 && [...new Set(this.items.map(i => i.contractTechId))].length === this.selectedItems.length
    },
    filteredItems() {
      return allSearchItems.filter(s => this.param.searchItems.includes(s.value))
    },
    fixChoiceWidth() {
      return 'calc(100% - ' + (this.$vuetify.application.left + 24) + 'px'
    }
  },
  watch: {
    checkAllContractDate(val) {
      if (!val) {
        if (!this.latestContractYears) {
          this.latestContractYears = 1
        }
      } else {
        this.latestContractYears = null
        this.param.endContractDate = null
        this.param.startContractDate = null
      }
    },
    checkAllDepositDate(val) {
      if (!val) {
        if (!this.latestDepositYears) {
          this.latestDepositYears = 1
        }
      } else {
        this.latestDepositYears = null
        this.param.endDepositDate = null
        this.param.startDepositDate = null
      }
    },
    latestContractYears(val) {
      if (val) {
        const now = moment()
        this.param.endContractDate = now.format('YYYY-MM-DD')
        this.param.startContractDate = now.subtract(val, 'years').format('YYYY-MM-DD')
      }
    },
    latestDepositYears(val) {
      if (val) {
        const now = moment()
        this.param.endDepositDate = now.format('YYYY-MM-DD')
        this.param.startDepositDate = now.subtract(val, 'years').format('YYYY-MM-DD')
      }
    }
  },
	beforeMount() {
    this.initLoading = true
		this.getCodeDetails()
    .finally(() => this.initLoading = false)
	},
  mounted() {
    this.param.searchItems = initSearchItems

    window.scrollTo(0, 0);

    const query = this.$route.query
    if (query && query.startDate) {
      this.param.startContractDate = query.startDate
    }
    if (query && query.endDate) {
      this.param.endContractDate = query.endDate
    }
    if (query && query.sw) {
      this.param.workFlowCodes = [query.sw]
      this.search()
    }
  },
  methods: {
    search() {
      this.loading = true
      const params = Object.assign({}, this.param)
      params.workFlowCodes = this.param.workFlowCodes.join(',')
      params.techTypes = this.param.techTypes.join(',')
      params.transactionTypes = this.param.transactionTypes.join(',')
      params.detailSearchItem = this.detailSearchItems.length > 0 ? this.detailSearchItems[0] : null
      this.$http.get('/api/contract/search', {
        params
      })
      .then(({ data }) => {
        // this.param.searchItems = initSearchItems
        this.orgItems = data
        this.items = data
        this.loading = false
      })
    },
		getCodeDetails() {
			return this.$http.get('/api/sys/code/detail/all')
				.then(({ data }) => {
					this.codes = data
				})
		},
		onChangeWorkFlowCode(e) {
			if (e.target.value === 'ALL') {
				if (e.target.checked) {
					this.param.workFlowCodes = ['ALL'].concat(this.codes['WKFLW'].map(c => c.codeDetailId))
				} else {
					this.param.workFlowCodes = []
				}
			} else {
				const idx = this.param.workFlowCodes.indexOf('ALL')
				if (idx >= 0) {
					this.param.workFlowCodes.splice(idx, 1)
				} else if (this.param.workFlowCodes.length >= this.codes['WKFLW'].length) {
					this.param.workFlowCodes.push('ALL')
				}
			}
		},
		onChangeTechType(e) {
			if (e.target.value === 'ALL') {
				if (e.target.checked) {
					this.param.techTypes = ['ALL'].concat(this.codes['7030'].map(c => c.codeDetailId))
				} else {
					this.param.techTypes = []
				}
			} else {
				const idx = this.param.techTypes.indexOf('ALL')
				if (idx >= 0) {
					this.param.techTypes.splice(idx, 1)
				} else if (this.param.techTypes.length >= this.codes['7030'].length) {
					this.param.techTypes.push('ALL')
				}
			}
		},
		onChangeTransactionType(e) {
			if (e.target.value === 'ALL') {
				if (e.target.checked) {
					this.param.transactionTypes = ['ALL'].concat(this.codes['7010'].map(c => c.codeDetailId))
				} else {
					this.param.transactionTypes = []
				}
			} else {
				const idx = this.param.transactionTypes.indexOf('ALL')
				if (idx >= 0) {
					this.param.transactionTypes.splice(idx, 1)
				} else if (this.param.transactionTypes.length >= this.codes['7010'].length) {
					this.param.transactionTypes.push('ALL')
				}
			}
		},
    onChangeDetailSearchItem(e) {
      if (e.target.checked) {
        this.detailSearchItems = [e.target.value]
      } else {
        this.detailSearchItems = []
      }
		},
    onChangeCheckAll(e) {
      if (e.target.checked) {
        this.selectedItems = [...new Set(this.items.map(i => i.contractTechId))]
      } else {
        this.selectedItems = []
      }
    },
    onChangeSideFilter(itemName, e) {
      if (e.target.checked) {
        this.sideFilterItems.push({
          field: itemName,
          value: e.target.value
        })
      } else {
        const findex = this.sideFilterItems.findIndex((i) => i.field === itemName && i.value === e.target.value)
        this.sideFilterItems.splice(findex, 1)
      }
      this.filterSideItems()
    },
    openSearchItemDialog() {
      this.searchItemDialog = true
    },
    closeSearchItemDialog(result) {
      this.searchItemDialog = false
      if (result) {
        this.param.searchItems = result
      }
    },
    containSearchItem(item) {
      return this.param.searchItems.includes(item)
    },
    countItem(itemName, value) {
      return [...new Set(this.orgItems.filter(i => i[itemName] === value).map(i => i.contractTechId))].length
    },
    filterItems(itemName, val) {
      if (val) {
        this.items = this.orgItems.filter(i => i[itemName] === val)
        this.currFilter = itemName + '#' + val
      } else {
        this.items = this.orgItems
        this.currFilter = null
      }
    },
    selectedFilter(itemName, val) {
      if (val) {
        return this.currFilter === itemName + '#' + val
      } else {
        return !this.currFilter
      }
    },
    getSideFilterValue(item) {
      let items = this.orgItems.map(i => i[item.value])
      const acc = {}
      items.forEach(i => {
        let f = i
        if (i === null) {
          f = '_na'
        }
        acc[f] = (acc[f] || 0) + 1;
      })

      items = [...new Set(items)].map(i => {
        return {value: i, count: i === null ? acc['_na'] : acc[i]}
      })

      if (item.code) {
        const codeItems = this.codes[item.code]
        items.sort((o1, o2) => {
          const o1C = codeItems.findIndex(c => c.codeDetailId === o1.value)
          const o2C = codeItems.findIndex(c => c.codeDetailId === o2.value)
          if (o1C > o2C) return 1
          if (o1C === o2C) return 0
          if (o1C < o2C) return -1
        })
      } else {
        items.sort((o1, o2) => {
          if (o1.value > o2.value) return 1
          if (o1.value === o2.value) return 0
          if (o1.value < o2.value) return -1
        })
      }
      return items
    },
    filterSideItems() {
      this.items = this.orgItems.filter(i => {
        let result = true
        if (this.currFilter) {
          const curF = this.currFilter.split('#')
          result = i[curF[0]] === curF[1]
        }

        if (result && this.sideFilterItems.length > 0) {
          result = false
          for (const sf of this.sideFilterItems) {
            if (!sf.value) {
              result = !i[sf.field]
              break;
            } else if (('' + i[sf.field]) === ('' + sf.value)) {
              result = true
              break;
            }
          }
        }
        return result
      })
    },
    resetSideFilter() {
      this.sideFilterItems = []
      this.sideFilterModel = []
      this.filterSideItems()
    },
    itemSelect(item) {
      window.open(`/application/#/detail/${item.contractTechId}`, item.contractTechId, 'menubar=no,width=1600,height=1000')
    },
    openDownloadApp() {
      this.$http.post('/api/contract/download/pre', this.selectedItems)
      .then(({ data }) => {
        window.open(`/download?did=${data}`, 'contractTechDownload', 'menubar=no,width=1600,height=1000')
      })
    },
    getCodeDetailName(codeId, codeDetailId) {
      for (let code of this.codes[codeId]) {
        if (code.codeDetailId === codeDetailId) {
          return code.codeDetailName
        }
      }
      return ''
    },
    getRegisterNoFormat(val) {
      if (val && val.length >= 10) {
        return `${val.substring(0, 6)}-${val.substring(6, 7)}******`
      } else {
        return ''
      }
    },
    scrollEvent() {
      const body = document.body
      const html = document.documentElement

      const documentHeight = Math.max( body.scrollHeight, body.offsetHeight,
        html.clientHeight, html.scrollHeight, html.offsetHeight )
      const windowHeight = window.innerHeight

      if(Math.round(window.scrollY) === (documentHeight - windowHeight)) {
        this.fixchoiceBottom = '95px'
      } else {
        this.fixchoiceBottom = '8px'
      }
    },
    setSort(fieldName) {
      const sort = this.param.sort
      if (sort.indexOf(fieldName) > -1) {
        if (sort.indexOf(',desc') > 0) {
          this.param.sort = ''
        } else {
          this.param.sort = `${fieldName},desc`
        }
      } else {
        this.param.sort = `${fieldName},asc`
      }
      this.search()
    },
    checkSort(fieldName) {
      const sort = this.param.sort
      if (sort.indexOf(fieldName) > -1) {
        if (sort.indexOf(',desc') > 0) {
          return 'mdi-arrow-down'
        } else {
          return 'mdi-arrow-up'
        }
      } else {
        return ''
      }
    },
    onClickDepositDate() {
      console.log('this.checkAllDepositDate', this.checkAllDepositDate)
      if (this.checkAllDepositDate) {
        this.checkAllDepositDate = false
      }
    },
    onClickContractDate() {
      console.log('this.checkAllContractDate', this.checkAllContractDate)
      if (this.checkAllContractDate) {
        this.checkAllContractDate = false
      }
    }
	},
  // created () {
  //   window.addEventListener('scroll', this.scrollEvent);
  // },
  // destroyed () {
  //   window.removeEventListener('scroll', this.scrollEvent);
  // }
}

const allSearchItems = [
  { value: 'manageNo', text: '관리번호' },
  { value: 'techTransDate', text: '계약일', format: 'date' },
  { value: 'repTechType', text: '기술유형(대표)', code: '7030' },
  { value: 'techTransManagerName', text: '책임자' },
  { value: 'techTransManagerDepartment', text: '책임자 소속' },
  { value: 'companyName', text: '업체명' },
  { value: 'repTechName', text: '기술명(대표)' },
  { value: 'totalAmountSum', text: '입금총액', format: 'money' },
  { value: 'contractTotalAmount', text: '계약총액', format: 'money' },
  { value: 'workFlowCode', text: '업무절차', code: 'WKFLW' },
  { value: 'transactionType', text: '거래유형', code: '7010' },
  { value: 'contractTechFeeReceiptType', text: '기술료 수취유형(계약)', code: '7040' },
  { value: 'contractPeriod', text: '계약종료일', format: 'date' },
  { value: 'depositYn', text: '입금여부' },
  { value: 'step', text: '차수' },
  { value: 'techFeeReceiptType', text: '기술료 수취유형(입금)', code: '7040' },
  { value: 'depositDate', text: '입금일', format: 'date' },
  { value: 'payInventorRewardYn', text: '발명자 보상금 지급여부' },
  { value: 'totalAmount', text: '입금금액(입금예정금액)', format: 'money' },
  { value: 'representTechYn', text: '대표기술 여부(Y/N)' },
  { value: 'techType', text: '기술유형', code: '7030' },
  { value: 'techName', text: '기술명(발명의 명칭)' },
  { value: 'applicationNo', text: '출원번호' },
  { value: 'registrationNo', text: '등록번호' },
  { value: 'inventorName', text: '성명' },
  { value: 'inventorDepartment', text: '소속' },
  { value: 'inventorCode', text: '주민번호' },
  { value: 'inventorType', text: '신분구분' },
  { value: 'contributeRation', text: '지분(%)' },
  { value: 'inventorReward', text: '발명자보상금(개인)', format: 'money' },
  { value: 'representProjectYn', text: '대표과제 여부(Y/N)' },
  { value: 'projectNo', text: '과제번호' },
  { value: 'projectManager', text: '과제책임자' },
  { value: 'totalResearchExpenses', text: '총연구비', format: 'money' },
  { value: 'managerLab', text: '관리연구소' },
  { value: 'researchContributeRation', text: '지분(%)' }
]
</script>

<style scoped>
table.search-table {
	width: 100%;
}
table.search-table th input[type=checkbox],
table.search-table td input[type=checkbox],
table.search-table td input[type=radio] {
	margin: 4px;
}
</style>
