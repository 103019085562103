<template>
	<div class="popup popup05">
    <v-card tile>
      <v-card-title class="popup-title">출력항목 설정<v-card-subtitle class="py-0">출력항목 설정 <span>최대 9개까지 선택 가능</span></v-card-subtitle></v-card-title>

      <div>
        <div class="popup21 pt-6">
          <fieldset>
            <table class="output">
              <colgroup>
                <col width="calc(100% / 3)">
                <col width="calc(100% / 3)">
                <col width="calc(100% / 3)">
              </colgroup>
              <tbody>
              <tr>
                <td class="td_bornon">
                  <p class="line03">
                    <span>
                      <input v-model="selectedItems" type="checkbox" id="setup01" name="set01" value="manageNo"><label for="setup01">관리번호</label>
                    </span>
                  </p>
                </td>
                <td class="td_bornon">
                  <p class="line03">
                    <span><input v-model="selectedItems" type="checkbox" id="setup02" name="set01" value="techTransDate"><label for="setup02">계약일</label></span>
                  </p>
                </td>
                <td class="td_bornon">
                  <p class="line03">
                    <span><input v-model="selectedItems" type="checkbox" id="setup03" name="set01" value="repTechType"><label for="setup03">기술유형 (대표)</label></span>
                  </p>
                </td>
              </tr>
              <tr>
                <td class="td_bornon">
                  <p class="line03">
                    <span><input v-model="selectedItems" type="checkbox" id="setup04" name="set01" value="techTransManagerName"><label for="setup04">책임자</label></span>
                  </p>
                </td>
                <td class="td_bornon">
                  <p class="line03">
                    <span><input v-model="selectedItems" type="checkbox" id="setup05" name="set01" value="techTransManagerDepartment"><label for="setup05">책임자 소속</label></span>
                  </p>
                </td>
                <td class="td_bornon">
                  <p class="line03">
                    <span><input v-model="selectedItems" type="checkbox" id="setup06" name="set01" value="companyName"><label for="setup06">업체명</label></span>
                  </p>
                </td>
              </tr>
              <tr>
                <td class="td_bornon">
                  <p class="line03">
                    <span><input v-model="selectedItems" type="checkbox" id="setup07" name="set01" value="repTechName"><label for="setup07">기술명 (대표)</label></span>
                  </p>
                </td>
                <td class="td_bornon">
                  <p class="line03">
                    <span><input v-model="selectedItems" type="checkbox" id="setup08" name="set01" value="totalAmountSum"><label for="setup08">입금총액</label></span>
                  </p>
                </td>
                <td class="td_bornon">
                  <p class="line03">
                    <span><input v-model="selectedItems" type="checkbox" id="setup09" name="set01" value="contractTotalAmount"><label for="setup09">계약총액</label></span>
                  </p>
                </td>
              </tr>
              <tr>
                <td class="td_bornon">
                  <p class="line03">
                    <span><input v-model="selectedItems" type="checkbox" id="setup10" name="set01" value="workFlowCode"><label for="setup10">업무절차</label></span>
                  </p>
                </td>
                <td class="td_bornon">
                  <p class="line03">
                    <span><input v-model="selectedItems" type="checkbox" id="setup11" name="set01" value="transactionType"><label for="setup11">거래유형</label></span>
                  </p>
                </td>
                <td class="td_bornon">
                  <p class="line03">
                    <span><input v-model="selectedItems" type="checkbox" id="setup12" name="set01" value="contractTechFeeReceiptType"><label for="setup12">기술료 수취유형(계약)</label></span>
                  </p>
                </td>
              </tr>
              <tr>
                <td class="td_bornon">
                  <p class="line03">
                    <span><input v-model="selectedItems" type="checkbox" id="setup13" name="set01" value="contractPeriod"><label for="setup13">계약종료일</label></span>
                  </p>
                </td>
                <td class="td_bornon">
                  <p class="line03">

                  </p>
                </td>
                <td class="td_bornon">
                  <p class="line03">

                  </p>
                </td>
              </tr>

              </tbody>
            </table>

            <table v-if="detailSearchItems.includes('A')">
              <colgroup>
                <col width="calc(100% / 3)">
                <col width="calc(100% / 3)">
                <col width="calc(100% / 3)">
              </colgroup>
              <tbody>
              <tr>
                <td class="td_bornon tit04" colspan="3">
                  입금내용
                </td>
              </tr>
              <tr>
                <td class="td_bornon">
                  <p class="line03">
                    <span><input v-model="selectedItems" type="checkbox" id="setup15" name="set02" value="depositYn"><label for="setup15">입금여부</label></span>
                  </p>
                </td>
                <td class="td_bornon">
                  <p class="line03">
                    <span><input v-model="selectedItems" type="checkbox" id="setup16" name="set02" value="step"><label for="setup16">차수</label></span>
                  </p>
                </td>
                <td class="td_bornon">
                  <p class="line03">
                    <span><input v-model="selectedItems" type="checkbox" id="setup17" name="set02" value="techFeeReceiptType"><label for="setup17">기술료 수취유형(입금)</label></span>
                  </p>
                </td>
              </tr>
              <tr>
                <td class="td_bornon">
                  <p class="line03">
                    <span><input v-model="selectedItems" type="checkbox" id="setup18" name="set02" value="depositDate"><label for="setup18">입금일</label></span>
                  </p>
                </td>
                <td class="td_bornon">
                  <p class="line03">
                    <span><input v-model="selectedItems" type="checkbox" id="setup19" name="set02" value="payInventorRewardYn"><label for="setup19">발명자 보상금 <br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;지급여부</label></span>
                  </p>
                </td>
                <td class="td_bornon">
                  <p class="line03">
                    <span><input v-model="selectedItems" type="checkbox" id="setup20" name="set02" value="totalAmount"><label for="setup20">입금금액<br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(입금예정금액)</label></span>
                  </p>
                </td>
              </tr>


              </tbody>
            </table>

            <table v-if="detailSearchItems.includes('B')">
              <colgroup>
                <col width="calc(100% / 3)">
                <col width="calc(100% / 3)">
                <col width="calc(100% / 3)">
              </colgroup>
              <tbody>
              <tr>
                <td class="td_bornon tit04" colspan="3">
                  기술 및 지식재산권
                </td>
              </tr>
              <tr>
                <td class="td_bornon">
                  <p class="line03">
                    <span><input v-model="selectedItems" type="checkbox" id="setup21" name="set03" value="representTechYn"><label for="setup21">대표기술 여부(Y/N)</label></span>
                  </p>
                </td>
                <td class="td_bornon">
                  <p class="line03">
                    <span><input v-model="selectedItems" type="checkbox" id="setup221" name="set03" value="techType"><label for="setup221">기술유형</label></span>
                  </p>
                </td>
                <td class="td_bornon">
                  <p class="line03">
                    <span><input v-model="selectedItems" type="checkbox" id="setup22" name="set03" value="techName"><label for="setup22">기술명(발명의 명칭)</label></span>
                  </p>
                </td>
              </tr>
              <tr>
                <td class="td_bornon">
                  <p class="line03">
                    <span><input v-model="selectedItems" type="checkbox" id="setup23" name="set03" value="applicationNo"><label for="setup23">출원번호</label></span>
                  </p>
                </td>
                <td class="td_bornon">
                  <p class="line03">
                    <span><input v-model="selectedItems" type="checkbox" id="setup24" name="set03" value="registrationNo"><label for="setup24">등록번호</label></span>
                  </p>
                </td>
                <td class="td_bornon">
                  <p class="line03">

                  </p>
                </td>
              </tr>


              </tbody>
            </table>

            <table v-if="detailSearchItems.includes('C')">
              <colgroup>
                <col width="calc(100% / 3)">
                <col width="calc(100% / 3)">
                <col width="calc(100% / 3)">
              </colgroup>
              <tbody>
              <tr>
                <td class="td_bornon tit04" colspan="3">
                  발명자 정보
                </td>
              </tr>
              <tr>
                <td class="td_bornon">
                  <p class="line03">
                    <span><input v-model="selectedItems" type="checkbox" id="setup25" name="set04" value="inventorName"><label for="setup25">성명</label></span>
                  </p>
                </td>
                <td class="td_bornon">
                  <p class="line03">
                    <span><input v-model="selectedItems" type="checkbox" id="setup26" name="set04" value="inventorDepartment"><label for="setup26">소속</label></span>
                  </p>
                </td>
                <td class="td_bornon">
                  <p class="line03">
                    <span><input v-model="selectedItems" type="checkbox" id="setup27" name="set04" value="inventorCode"><label for="setup27">주민번호</label></span>
                  </p>
                </td>
              </tr>
              <tr>
                <td class="td_bornon">
                  <p class="line03">
                    <span><input v-model="selectedItems" type="checkbox" id="setup28" name="set04" value="inventorType"><label for="setup28">신분구분</label></span>
                  </p>
                </td>
                <td class="td_bornon">
                  <p class="line03">
                    <span><input v-model="selectedItems" type="checkbox" id="setup29" name="set04" value="contributeRation"><label for="setup29">지분(%)</label></span>
                  </p>
                </td>
                <td class="td_bornon">
                  <p class="line03">
                    <span><input v-model="selectedItems" type="checkbox" id="setup30" name="set04" value="inventorReward"><label for="setup30">발명자보상금(개인)</label></span>
                  </p>
                </td>
              </tr>


              </tbody>
            </table>

            <table v-if="detailSearchItems.includes('D')">
              <colgroup>
                <col width="calc(100% / 3)">
                <col width="calc(100% / 3)">
                <col width="calc(100% / 3)">
              </colgroup>
              <tbody>
              <tr>
                <td class="td_bornon tit04" colspan="3">
                  관련 연구과제
                </td>
              </tr>
              <tr>
                <td class="td_bornon">
                  <p class="line03">
                    <span><input v-model="selectedItems" type="checkbox" id="setup31" name="set05" value="representProjectYn"><label for="setup31">대표과제 여부(Y/N)</label></span>
                  </p>
                </td>
                <td class="td_bornon">
                  <p class="line03">
                    <span><input v-model="selectedItems" type="checkbox" id="setup32" name="set05" value="projectNo"><label for="setup32">과제번호</label></span>
                  </p>
                </td>
                <td class="td_bornon">
                  <p class="line03">
                    <span><input v-model="selectedItems" type="checkbox" id="setup33" name="set05" value="projectManager"><label for="setup32">과제책임자</label></span>
                  </p>
                </td>
              </tr>
              <tr>
                <td class="td_bornon">
                  <p class="line03">
                    <span><input v-model="selectedItems" type="checkbox" id="setup34" name="set05" value="totalResearchExpenses"><label for="setup34">총연구비</label></span>
                  </p>
                </td>
                <td class="td_bornon">
                  <p class="line03">
                    <span><input v-model="selectedItems" type="checkbox" id="setup35" name="set05" value="managerLab"><label for="setup35">관리연구소</label></span>
                  </p>
                </td>
                <td class="td_bornon">
                  <p class="line03">
                    <span><input v-model="selectedItems" type="checkbox" id="setup36" name="set05" value="researchContributeRation"><label for="setup36">지분(%)</label></span>
                  </p>
                </td>
              </tr>
              </tbody>
            </table>

          </fieldset>
        </div>
      </div>
      <v-card-actions>
        <button id="close21" type="button" class="pop-btn" @click="select">선택완료</button>
      </v-card-actions>
    </v-card>

	</div>
</template>

<script>
export default {
	name: 'SearchItemDialog',
  props: {
    searchItems: Array,
    detailSearchItems: Array
  },
  data() {
		return {
      selectedItems: [],
      items: [
        { value: 'manageNo', text: '관리번호' },
        { value: 'techTransDate', text: '계약일' },
        { value: 'repTechType', text: '기술유형(대표)' },
        { value: 'techTransManagerName', text: '책임자' },
        { value: 'techTransManagerDepartment', text: '책임자 소속' },
        { value: 'companyName', text: '업체명' },
        { value: 'repTechName', text: '기술명(대표)' },
        { value: 'totalAmountSum', text: '입금총액' },
        { value: 'contractTotalAmount', text: '계약총액' },
        { value: 'workFlowCode', text: '업무절차' },
        { value: 'transactionType', text: '거래유형' },
        { value: 'contractTechFeeReceiptType', text: '기술료 수취유형(계약)' },
        { value: 'contractPeriod', text: '계약종료일' },
        { value: 'depositYn', text: '입금여부' },
        { value: 'step', text: '차수' },
        { value: 'techFeeReceiptType', text: '기술료 수취유형(입금)' },
        { value: 'depositDate', text: '입금일' },
        { value: 'payInventorRewardYn', text: '발명자 보상금 지급여부' },
        { value: 'totalAmount', text: '입금금액(입금예정금액)' },
        { value: 'representTechYn', text: '대표기술 여부(Y/N)' },
        { value: 'techType', text: '기술유형' },
        { value: 'techName', text: '기술명(발명의 명칭)' },
        { value: 'applicationNo', text: '출원번호' },
        { value: 'registrationNo', text: '등록번호' },
        { value: 'inventorName', text: '성명' },
        { value: 'inventorDepartment', text: '소속' },
        { value: 'inventorCode', text: '주민번호' },
        { value: 'inventorType', text: '신분구분' },
        { value: 'contributeRation', text: '지분(%)' },
        { value: 'inventorReward', text: '발명자보상금(개인)' },
        { value: 'representProjectYn', text: '대표과제 여부(Y/N)' },
        { value: 'projectNo', text: '과제번호' },
        { value: 'projectManager', text: '과제책임자' },
        { value: 'totalResearchExpenses', text: '총연구비' },
        { value: 'managerLab', text: '관리연구소' },
        { value: 'researchContributeRation', text: '지분(%)' }
      ]
		}
	},
  mounted() {
    this.activateMultipleDraggableDialogs()
    this.selectedItems = [].concat(this.searchItems)
  },
	methods: {
		select() {
			if (!this.selectedItems) {
				alert('항목을 선택해 주세요.')
				return
			} else if (this.selectedItems.length > 9) {
        alert('출력항목은 최대 9개까지만 선택 가능합니다.')
        return
      }

			this.$emit('close', this.selectedItems)
		},
		close() {
			this.$emit('close')
		}
	},
}
</script>
